<template>
    <DashboardProducao />
</template>

<script>
    import DashboardProducao from '../../components/consulta/DashboardProducao.vue';

    export default {
        components: { DashboardProducao },
        setup() {
            
        },
    }
</script>